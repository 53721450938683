// ImageSlider.js
import { useEffect, useState } from 'react';
import styles from './ImageSlider.module.css'; // Import your CSS file
import Image from '../../components/common/Image';
import { Col, Row } from 'react-bootstrap';

const ImageSlider = ({ slides }) => {
	let baseUrl = process.env.NEXT_PUBLIC_REACT_APP_RESOURCE_BASE_URL;
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		// Optional: Auto-play functionality
		const interval = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
		}, 5000);

		return () => clearInterval(interval);
	}, [slides.length]);


	return (
		<Row className="mobile-banner g-0">
			<Col lg={12} md={12}>
				<div className={styles.trooSliderContainer}>
					{slides.map((slide, i) => {
						const imgUrl = slide?.Image.data.attributes.url.replace(
							"uploads",
							"uploads/f_webp"
						);
						return (
							<div
								key={i}
								className={`banner-img ${styles.slide} ${i === currentIndex ? styles.active : ''}`}
							>
								{slide.Image ? (
									<Image
										priority={(i == 0 || i == 1) ? true : false}
										width={450}
										height={566}
										sizes="(max-width: 768px) 75vw, (max-width: 1200px) 40vw, 25vw"
										layout="responsive"
										loading={(i == 0 || i == 1) ? 'eager' : 'lazy'}
										quality={75}
										blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAcIAAAI2AQMAAAD5PRqUAAAAA1BMVEX29vYACyOqAAAANklEQVR42u3BMQEAAADCIPunNsReYAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAcoA8AAEvqX8xAAAAAElFTkSuQmCC"
										placeholder='blur' // "empty" | "blur"
										className="img-banner-image"
										src={`${baseUrl}${imgUrl}`}
										alt={
											slide?.Image?.data?.attributes
												?.alternativeText
												? slide?.Image?.data
													?.attributes
													?.alternativeText
												: slide?.Image?.data
													?.attributes?.name
										}
									/>
								) : (
									<Image
										priority
										width={0}
										height={0}
										sizes="100vw"
										style={{
											width: "100%",
											height: "auto",
										}}
										loading="eager"
										className="img-banner-image"
										src={require("../../assets/images/banner-img.png")}
										alt=""
									/>
								)}
							</div>
						);
					})}
				</div>
			</Col>
		</Row>
	);
};

export default ImageSlider;
